<template>
  <v-dialog v-model="open" width="800" persistent>
    <v-card>
      <v-card-text class="px-2 tw-bg-gray-100">
        <div class="tw-flex tw-justify-end tw-items-center tw-pt-2">
          <v-icon large @click="$emit('close-dialog')">mdi-close</v-icon>
        </div>

        <div class="tw-px-6 tw-pb-6 tw-pt-3">
          <div
            v-if="isLoading"
            class="tw-flex tw-justify-center tw-items-center tw-py-8"
          >
            <v-progress-circular indeterminate></v-progress-circular>
          </div>

          <div v-else-if="!isLoading && tatReport && timeline">
            <v-timeline>
              <v-timeline-item
                v-for="(process, index) in timeline"
                :key="index"
                :icon="process.icon"
                color="primary"
                large
              >
                <template v-slot:opposite>
                  <span
                    ><span class="tw-text-black">
                      {{ process.started | formatToHumanWithTime }}
                    </span>
                    -
                    <span class="tw-text-black">
                      {{ process.ended | formatToHumanWithTime }}
                    </span></span
                  >
                </template>
                <v-card elevation="3">
                  <v-card-title class="text-h5">
                    <span class="tw-text-base tw-font-semibold">{{
                      formatTitle(process.name)
                    }}</span>
                  </v-card-title>
                  <v-card-text>
                    <div v-if="process.name == 'BOOKING'" class="tw-mb-2">
                      <span class="c-blue-text">{{ tatReport.bookingId }}</span>

                      <div class="tw-flex tw-items-center tw-gap-2 tw-mt-2">
                        <span class="tw-text-black">Reg No.: </span>
                        <span>{{
                          tatReport.bookingData.vehicle.registrationNumber
                        }}</span>
                      </div>
                    </div>

                    <div class="tw-flex tw-items-center tw-gap-2 tw-mb-2">
                      <span class="tw-text-black">Duration: </span>
                      <span>{{ process.timeTaken }}</span>
                    </div>

                    <v-chip
                      :color="
                        process.status == 'OnTime'
                          ? 'success'
                          : process.status == 'InProgress'
                          ? 'secondary'
                          : process.status == 'Missing TAT Config' ||
                            process.status == 'N/A'
                          ? 'gray'
                          : 'error'
                      "
                      small
                    >
                      {{ process.status }}
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>

            <v-card elevation="3">
              <v-card-text>
                <div class="tw-flex tw-flex-col tw-gap-2 tw-mt-4">
                  <div
                    class="tw-flex tw-gap-2"
                    v-for="(actor, index) in actors"
                    :key="index"
                  >
                    <span class="tw-text-black tw-w-32"
                      >{{ actor.title }}:
                    </span>

                    <div
                      v-if="
                        !actor.email && !actor.fullName && !actor.phoneNumber
                      "
                    >
                      <span>N/A</span>
                    </div>

                    <div
                      class="tw-grid tw-grid-cols-3 tw-gap-6 tw-w-3/4"
                      v-else
                    >
                      <span>
                        <span v-if="!!actor.email">{{ actor.email }}</span>
                        <span v-else class="tw-text-center tw-block tw-w-full"
                          >-</span
                        >
                      </span>

                      <span>
                        <span v-if="!!actor.fullName">{{
                          actor.fullName
                        }}</span>
                        <span v-else class="tw-text-center tw-block tw-w-full"
                          >-</span
                        >
                      </span>
                      <span v-if="actor.phoneNumber">{{
                        actor.phoneNumber
                      }}</span>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div
            v-else
            class="
              tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-8
            "
          >
            <p class="c-gray-text">Failed to get TAT report</p>

            <v-btn color="secondary" @click="fetchTatReport">Retry</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { formatToHumanWithTime } from '@/utils/time'

export default {
  emits: ['close-dialog'],
  props: {
    id: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    tatReport: null,
  }),
  computed: {
    timeline() {
      if (!this.isLoading && this.tatReport) {
        return [
          {
            icon: 'mdi-car',
            ...this.tatReport.booking,
          },
          {
            icon: 'mdi-car-search',
            ...this.tatReport.inspection,
          },
          {
            icon: 'mdi-file-document',
            ...this.tatReport.bidTemplatePreparation,
          },
          {
            icon: 'mdi-text-box-plus',
            ...this.tatReport.bidding,
          },
          {
            icon: 'mdi-text-box-search',
            ...this.tatReport.bidAnalysis,
          },
          {
            icon: 'mdi-text-box-check',
            ...this.tatReport.bidApproval,
          },
          {
            icon: 'mdi-car-back',
            ...this.tatReport.assessmentCheckout,
          },
          { icon: 'mdi-car', ...this.tatReport.garageCheckin },
          { icon: 'mdi-timer-sand', ...this.tatReport.awaitingRepairs },
          { icon: 'mdi-car-wrench', ...this.tatReport.repairsInProgress },
          {
            icon: 'mdi-file-document',
            ...this.tatReport.awaitingSupplementaryApproval,
          },
          { icon: 'mdi-file-check', ...this.tatReport.supplementaryApproval },
          { icon: 'mdi-magnify', ...this.tatReport.reInspection },
          { icon: 'mdi-car-back', ...this.tatReport.release },
        ].filter((item) => item.status !== 'N/A')
      }
      return null
    },
    actors() {
      if (!this.isLoading && this.tatReport) {
        return [
          {
            title: 'Internal Assessor',
            email: this.tatReport.bookingData.internalAssessor.email,
            fullName: this.tatReport.bookingData.internalAssessor.fullName,
          },
          {
            title: 'External Assessor',
            email: this.tatReport.bookingData.externalAssessor.email,
            fullName: this.tatReport.bookingData.externalAssessor.fullName,
          },
          {
            title: 'Approver',
            email: this.tatReport.bookingData.approver.email,
            fullName: this.tatReport.bookingData.approver.fullName,
          },
          {
            title: 'Analyst',
            email: this.tatReport.bookingData.analyst.email,
            fullName: this.tatReport.bookingData.analyst.fullName,
          },
          {
            title: 'Claim Officer',
            email: this.tatReport.bookingData.claimOfficer.email,
            fullName: this.tatReport.bookingData.claimOfficer.fullName,
          },
          {
            title: 'Garage',
            email: this.tatReport.bookingData.garage.email,
            fullName: this.tatReport.bookingData.garage.name,
            phoneNumber: this.tatReport.bookingData.garage.phoneNumber,
          },
        ]
      }

      return []
    },
  },
  methods: {
    async fetchTatReport() {
      this.isLoading = true

      axios
        .get(`/motor-assessment/api/reports/tat/timeline/${this.id}`)
        .then(({ data }) => {
          if (data.success) {
            this.tatReport = data.data
          } else {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: data.msg ?? 'Failed to fetch timeline',
              variant: 'error',
            })
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.message ?? 'Failed to fetch timeline',
            variant: 'error',
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    formatTitle(input) {
      return input.replace(/_/g, ' ')
    },
  },
  mounted() {
    this.fetchTatReport()
  },
  filters: {
    formatToHumanWithTime,
  },
}
</script>
